export const SITE_SETTINGS = {
    siteName: 'Travel Time Taxi',
    phoneNo: '8148777877',
    whatsapp: '+918148777877',
    websiteAddress: 'www.traveltimetaxi.in',
    smsKey: 'u6CX1GveqyzwnHL8S7Nbh3BTYpaZ0FJdtxV5QI9MDOlUK24rWj7FkX2lr3ouONIRadsH18U6GqKeT5C9',
    enquiryEmail: 'booking@traveltimetaxi.com',
    emailAPI: 'https://us-central1-calvincare-emailservice.cloudfunctions.net/sendEmail',
    smsAPI: 'https://us-central1-smsapi-service.cloudfunctions.net/sendSms/api/v1/sendsms',
    quickEnquiry: 'https://us-central1-smsapi-service.cloudfunctions.net/quickEnquiry',
    telegramAPI: 'https://us-central1-telegram-api-service.cloudfunctions.net/telegramAPI/api/v1/telegram',
    siteInfo: `Toll Gate, Permit, Hill Station Charges Extra`,
    adminEmailSubject: 'Website Enquiry Email From',
    bannerColor: "#fff",
    appPassword: 'mfrjbbeigeeieooi',
    appEmail: 'kalkikailan@gmail.com'
}