<!-- / -->
<!-- Start Header -->
<header class="site-header">
    <div class="header-logo">
        <a title="Miro - HTML5 Template">
            <img src="/assets/logo.png" style="width: 160px;" alt="" srcset="">
        </a>
    </div>
    <div class="header-menu button-open-sidenav">
        <a href="#">
            <span></span>
        </a>
    </div>
    <div class="header-follow">
        <ul>
            <li>
                <a href="#"><i class="ti-twitter-alt"></i></a>
            </li>
            <li>
                <a href="#"><i class="ti-github"></i></a>
            </li>
            <li>
                <a href="#"><i class="ti-vimeo-alt"></i></a>
            </li>
        </ul>
        <p>Follow Me</p>
    </div>
</header>
<!-- End Header -->
<!-- Start Sidenav -->
<div class="site-sidenav">
    <div class="sidenav-menu">
        <img src="/assets/logo.png" style="width: 150px;position: relative;top: 25px;" alt="" srcset="">
        <button class="button button-close-sidenav"></button>
        <ul class="menu">
            <li>
                <a href="#home">Home</a>
            </li>
            <li>
                <a href="#about">About</a>
            </li>

            <li>
                <a href="#tariff">Tariff</a>
            </li>
            <li>
                <a href="#contact">Contact</a>
            </li>
        </ul>
    </div>
    <span class="sidenav-close"></span>
</div>
<!-- End Sidenav -->
<!-- Start Wrapper -->
<div id="site-wrapper">
    <!-- Start Hero -->
    <section class="section section-hero section-hero-4">
        <div class="hero-content">
            <span class="shape-1" style="background-image: url(assets/images/music-hero-1-1.png);"></span>
            <div id="particles-js-one" class="particles-js"></div>
            <div class="owl-carousel carousel-hero">
                <div class="item">
                    <div class="overlay-image image" style="background-image: url(assets/images/music-hero-2.jpg);">
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row row-0">
                    <div class="col-lg-6">
                        <div class="hero-box">
                            <mat-horizontal-stepper linear #stepper>
                                <mat-step>
                                    <p class="hero-icon"><span>Book your outstation taxi</span></p>
                                    <fieldset><label class="wo-titleinput one">I’m Looking For:</label>
                                        <div class="wo-radioholder"><span class="wo-radio"><input
                                                    (click)="selectTrip('One Way')" type="radio" id="Buy" value="oneway"
                                                    name="triptype" checked="" formcontrolname="triptype"
                                                    class="ng-untouched ng-pristine ng-valid"><label for="Buy"><span><i
                                                            class="far fa-circle"></i>One
                                                        Way</span></label></span><span class="wo-radio"><input
                                                    (click)="selectTrip('Round Trip')" type="radio" id="Rent"
                                                    name="triptype" value="roundtrip" formcontrolname="triptype"
                                                    class="ng-untouched ng-pristine ng-valid"><label for="Rent"><span><i
                                                            class="far fa-circle"></i>Round Trip</span></label></span>
                                        </div>
                                    </fieldset>

                                    <form [formGroup]="userFromGroup" class="form form-1">
                                        <div class="half">
                                            <div class="form-item">
                                                <input type="text" formControlName="name" />
                                                <label for="userName">Your Name</label>
                                                <div *ngIf="submitted && contactValidation.name.errors"
                                                    class="invalid-feedback" style="display: block;">
                                                    <div *ngIf="contactValidation.name?.errors.required">Name is
                                                        required</div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="half">
                                            <div class="form-item">
                                                <input type="text" formControlName="phoneNo" />
                                                <label for="userEmail">Your Mobile</label>
                                                <div *ngIf="submitted && contactValidation.phoneNo.errors"
                                                    class="invalid-feedback" style="display: block;">
                                                    <div *ngIf="contactValidation.phoneNo?.errors.required">Mobile
                                                        number
                                                        is
                                                        required
                                                    </div>
                                                    <div *ngIf="contactValidation.phoneNo?.errors.pattern">Enter valid
                                                        mobile
                                                        number
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="half">
                                            <div class="form-item">
                                                <input type="text" #origin />
                                                <label for="userName">Pickup Address</label>
                                            </div>
                                        </div>
                                        <div class="half">
                                            <div class="form-item">
                                                <input type="text" #destination />
                                                <label for="userEmail">Drop Address</label>
                                            </div>
                                        </div>
                                        <div class="half">
                                            <div class="form-item">
                                                <input [min]="minDate" (focus)="picker.open()" [matDatepicker]="picker"
                                                    type="text" formControlName="pickupDate" />
                                                <label for="userName">Pickup Date</label>
                                                <mat-datepicker #picker></mat-datepicker>
                                                <div *ngIf="submitted && contactValidation.pickupDate.errors"
                                                    class="invalid-feedback" style="display: block;">
                                                    <div *ngIf="contactValidation.pickupDate?.errors.required">Pickup
                                                        date is
                                                        required</div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="half">
                                            <div class="form-item">
                                                <input type="text" formControlName="pickupTime"
                                                    [ngxTimepicker]="time" />
                                                <label for="userEmail">Pick Time</label>
                                                <ngx-material-timepicker #time></ngx-material-timepicker>
                                                <div *ngIf="submitted && contactValidation.pickupTime.errors"
                                                    class="invalid-feedback" style="display: block;">
                                                    <div *ngIf="contactValidation.pickupTime?.errors.required">Pickup
                                                        time is
                                                        required</div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>

                                    <div class="car-wrap">
                                        <div *ngFor="let car of cars" class="car-item">
                                            <div class="cars-item" (click)="selectedCarType(car)">
                                                <label>{{car.carType}}</label>
                                                <img *ngIf="car.carType !== selectedCarName" src="{{car.image}}">
                                                <img *ngIf="car.carType === selectedCarName" src="{{car.activeImage}}">
                                                <label>{{ tripType !== 'Round Trip' ? car.oneway : car.roundtrip}} ₹ /
                                                    Km</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 text-center">
                                        <button (click)="booknow()" class="button button-md button-primary"
                                            name="submit" type="submit">Get
                                            Estimate</button>
                                    </div>
                                </mat-step>
                                <mat-step>
                                    <div *ngIf="show" class="estimate-wrap">
                                        <p>Trip estimation for {{bookingDetails.originCityName}} <i
                                                class="fa fa-arrows-h" aria-hidden="true"></i>
                                            {{bookingDetails.destinationCityName}}</p>
                                        <strong style="
                                        display: block;
                                        font-size: 32px;
                                        color: #fe0101;
                                    ">
                                            <span>Fare</span> {{bookingDetails.tripEstimation | currency:'INR' }}
                                        </strong>
                                        <b> Total Distance: </b>{{bookingDetails.distance}} KM <br>
                                        <b>Total Duration:</b> {{bookingDetails.totalDuration}} <br>
                                        <em>Note: Above estimation is exclusive of Toll Gate and State Permit Etc</em>
                                        <div class="col-12 text-center mt-3 mb-3">
                                            <button (click)="confirm()" class="button button-md button-primary"
                                                name="submit" type="submit">Confirm Booking</button>
                                        </div>
                                    </div>
                                </mat-step>

                                <mat-step>
                                    <h4>Ride Booked Successfully</h4>
                                    <img src="assets/success.gif" alt="" srcset="">
                                    <p>
                                        Thanks for Choosting {{settings.siteName}}, reservation details have been sent
                                        to your phone.
                                    </p>
                                    <button (click)="home()" class="button button-md button-primary" name="submit"
                                        type="submit">Home</button>
                                </mat-step>


                            </mat-horizontal-stepper>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <agm-map class="hide" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
            <ng-container *ngIf="showDirection">
                <agm-direction [origin]="pickupPlace" [destination]="dropPlace" (onResponse)="onResponses($event)">
                </agm-direction>
            </ng-container>
        </agm-map>
    </section>
    <!-- End Hero -->
    <!-- Start About -->
    <section id="about" class="section section-about section-about-1 pp-scrollable">
        <div class="display-screen">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5">
                        <div class="section-box">
                            <div class="section-box-image overlay-image"
                                style="background-image: url(assets/images/music-about.jpg);"></div>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="about-content">
                            <div class="section-title section-title-3">
                                <h2><span>About Us</span></h2>
                                <h3><span>{{settings.siteName}}</span></h3>
                                <div class="divider divider-1"></div>
                                <p>
                                    {{settings.siteName}} is one of the leading cab service in South India, provides its
                                    services between various locations across Tamil Nadu at reasonable price. Nathan
                                    Travels has been providing car rental services to clients in Chennai for more than
                                    Five year. We provide one way and round trip drop taxi service all over South India.

                                </p>
                            </div>
                            <ul class="row">
                                <li class="col-12 col-sm-12 col-md-6">
                                    <div class="feature-item">
                                        <i class="icon far fa-user-headset"></i>
                                        <div class="feature-item-content">
                                            <h5>24 / 7 Car Support</h5>
                                            <p>
                                                We Offer 24x7 Pickup Up And Drop Facility In Tamil Nadu To Facilitate
                                                The Customers Emergency Needs.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li class="col-12 col-sm-12 col-md-6">
                                    <div class="feature-item">
                                        <i class="icon far fa-stars"></i>
                                        <div class="feature-item-content">
                                            <h5>Top Rated Driver - Partner</h5>
                                            <p>All Our Driver trainer To Delivery The Best Experience. </p>
                                        </div>
                                    </div>
                                </li>
                                <li class="col-12 col-sm-12 col-md-6">
                                    <div class="feature-item">
                                        <i class="icon fas fa-suitcase-rolling"></i>
                                        <div class="feature-item-content">
                                            <h5>Advance Booking Available</h5>
                                            <p>Quick Outstation Ride And OneWay Taxi Service</p>
                                        </div>
                                    </div>
                                </li>
                                <li class="col-12 col-sm-12 col-md-6">
                                    <div class="feature-item">
                                        <i class="icon fas fa-taxi"></i>
                                        <div class="feature-item-content">
                                            <h5>Best Cab Provider</h5>
                                            <p>Provide You Enormous Car Options For your One Way Taxi Booking
                                                Preference. </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End About -->
    <!-- Start Resume -->
    <ng-container *ngFor="let place of places">
        <section id="{{place.city}}" class="section section-resume section-resume-1 pp-scrollable">
            <div class="display-screen">
                <div class="section-title-2">
                    <div class="container">
                        <div class="inner max-w-576">
                            <h2><span>Popular Outstation</span></h2>
                            <h3>{{place.city}} City Routes</h3>
                            <div class="divider divider-1-reverse divider-1-1"></div>
                            <div class="divider divider-1-reverse divider-1-2"></div>
                        </div>
                    </div>
                </div>
                <div class="container container-resume">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="resume-row">
                                <h4 class="resume-title">{{place.city}} One Way Taxi</h4>
                                <ul>
                                    <li *ngFor="let route of place.routes">
                                        <div class="resume-item">
                                            <div class="resume-head">
                                                <span class="resume-icon">
                                                    <span style="
                                                  position: absolute;
                                                  z-index: 12;
                                                  width: 100px;
                                                  top: 21px;
                                                  left: -8px;
                                                  font-weight: 700;
                                              "> ₹ {{route.estimation}}</span>
                                                </span>
                                                <h5>{{route.origin}} to {{route.destination}}</h5>
                                                <h6>Total Distance: {{route.distance}} | Total Duration
                                                    <small>{{route.duration}}</small>
                                                </h6>
                                            </div>
                                            <div class="resume-body">
                                                <p>
                                                    Book {{route.origin}} to {{route.destination}} Cabs at lowest price
                                                    ₹{{route.estimation}} from {{settings.siteName}}. Get best deals for
                                                    {{route.origin}} to {{route.destination}} car rentals. Compare from
                                                    variety of cars.
                                                </p>
                                            </div>
                                        </div>
                                    </li>


                                </ul>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </section>

    </ng-container>

    <!-- End Resume -->
    <!-- Start Testimonials -->
    <section id="tariff" class="section section-testimonials section-testimonials-1 pp-scrollable">
        <div class="display-screen">
            <div class="section-title-2">
                <div class="container">
                    <div class="inner max-w-576">
                        <h2><span>Our Tariff</span></h2>
                        <h3>Outstation Tariff</h3>
                        <div class="divider divider-1-reverse divider-1-1"></div>
                        <div class="divider divider-1-reverse divider-1-2"></div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-xl-6">
                        <div class="owl-carousel carousel-testimonials-1 carousel-style-2">
                            <div class="testimonials-item testimonials-clients">
                                <div class="type">
                                    <span>SEDAN</span>
                                </div>
                                <p>One Way: ₹13</p>
                                <p>Round Trip: ₹12</p>

                                <div class="author">
                                    <img src="assets/c1.png" alt="Relevant textual alternative">
                                    <h4>SWIFT DZIRE, XCENT, AND SIMILAR</h4>
                                    <h5>Avilable Cars</h5>
                                </div>
                            </div>
                            <div class="testimonials-item testimonials-clients">
                                <div class="type">
                                    <span>SUV</span>
                                </div>
                                <p>One Way: ₹18</p>
                                <p>Round Trip: ₹16</p>
                                <div class="author">
                                    <img src="assets/c2.png" alt="Relevant textual alternative">
                                    <h4>INNOVA</h4>
                                    <h5>Avilable Cars</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </section>
    <!-- End Testimonials -->

    <!-- Start Contact Me -->
    <section id="contact" class="section section-contact section-contact-1 pp-scrollable">
        <div class="display-screen">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5">
                        <div class="section-box">
                            <div class="section-box-image overlay-image"
                                style="background-image: url(assets/images/music-contact.jpg);"></div>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="about-content test">
                            <div class="section-title section-title-3">
                                <h2><span>Contact</span></h2>
                                <h3>Get in touch with Us today.</h3>
                                <div class="divider divider-1"></div>
                                <p> {{settings.siteName}} is one of the leading cab service in South India, provides its
                                    services between various locations across Tamil Nadu at reasonable price.</p>

                                <h5>Address</h5>
                                <p>
                                    PLOT 3, Mettukuppam Rd, Kanchuma Puram, Dhanalakshmi Nagar, Vanagaram, Chennai,
                                    Tamil Nadu 600095

                                </p>



                                <h5>Call Us today</h5>

                                <a href="tel:{{settings.phoneNo}}"> {{settings.phoneNo}} </a> <br>

                                <h5>Email Us</h5>

                                <a href="mailto:{{settings.enquiryEmail}}"> {{settings.enquiryEmail}}</a>



                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Contact Me -->

    <div class="phone"><a href="tel:{{settings.phoneNo}}"><img src="/assets/phone.png"></a></div>
    <div class="what"><a href="https://wa.me/{{settings.whatsapp}}"><img src="/assets/whatsapp.png"></a></div>
</div>