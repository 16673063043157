import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import * as moment from 'moment';
import { PLACES } from 'src/places';
import { CARS } from './cars';
import { SITE_SETTINGS } from './settings';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @ViewChild('origin', { read: ElementRef }) originElementRef: ElementRef;
  @ViewChild('destination', { read: ElementRef }) destinationElementRef: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;

  cars = CARS;
  selectedCar;
  requestRide;
  userFromGroup: FormGroup;
  latitude: number = 11.127123;
  longitude: number = 78.656891;
  zoom: number = 12;
  origin;
  destination;
  showDirection = false;
  pickupPlace;
  dropPlace;
  totalDistance;
  totalDuration;
  originCityName;
  destinationCityName;
  routeDetails;
  submitted = false;
  settings = SITE_SETTINGS
  bookingDetails;
  minDate = new Date();
  tripType = 'One Way';
  estimation;
  selectedCarName;
  show = false;
  bookingD;
  places = PLACES;

  constructor(private fb: FormBuilder, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private http: HttpClient) { }

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      const origin = this.originElementRef?.nativeElement;
      const destination = this.destinationElementRef?.nativeElement;

      this.addMapEventListener(origin, 'origin');
      this.addMapEventListener(destination, 'destination');
    });

    this.userFromGroup = this.fb.group({
      triptype: ['oneway', Validators.required],
      name: ['', Validators.required],
      phoneNo: ['', [
        Validators.required,
        Validators.pattern(/^[0]?[6789]\d{9}$/)
      ]],
      pickupDate: [new Date, Validators.required],
      pickupTime: ['', Validators.required]
    });

    this.userFromGroup.get('pickupTime').setValue(moment(Date.now()).format('h:mm a'));

  }

  selectTrip(type) {
    this.tripType = type;
  }

  get contactValidation() { return this.userFromGroup.controls; }


  addMapEventListener(input, type) {
    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.setComponentRestrictions({
      country: ["in"],
    });
    autocomplete.addListener("place_changed", () => {

      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        (type === 'origin') ? this.origin = place.formatted_address : this.destination = place.formatted_address;
        (type === 'origin') ? this.originCityName = place.name : this.destinationCityName = place.name;

        if (this.origin && this.destination) {
          this.showDirection = true;
          this.pickupPlace = this.origin;
          this.dropPlace = this.destination;
        }

        this.zoom = 12;
      });
    });
  }

  selectedCarType(ev) {
    this.selectedCar = ev;
    this.selectedCarName = ev.carType;
  }

  home() {
    this.stepper.selectedIndex = 0;
    this.userFromGroup.reset();
  }

  onResponses(event) {
    this.routeDetails = {
      'origin': this.pickupPlace,
      'destination': this.dropPlace,
      'originCityName': this.originCityName,
      'destinationCityName': this.destinationCityName,
      'totalDistance': event.routes[0].legs[0].distance.text.replace(/[^0-9.-]+/g, ""),
      'totalDuration': event.routes[0].legs[0].duration.text
    }
  }

  getEstimation() {
    (this.routeDetails.totalDistance <= 130) ? this.routeDetails.totalDistance = 130 : this.routeDetails.totalDistance;
    console.log(this.selectedCar)
    console.log((this.routeDetails.totalDistance * 2) * this.selectedCar.roundTrip + this.selectedCar.roundTripDriverFee);
    this.estimation = {
      tripEstimation: (this.tripType === 'One Way') ? this.routeDetails.totalDistance * this.selectedCar.oneway + this.selectedCar.onewayDriverFee : (this.routeDetails.totalDistance * 2) * this.selectedCar.roundtrip + this.selectedCar.roundTripDriverFee,
      ratePerKM: (this.tripType === 'One Way') ? this.selectedCar.oneway : this.selectedCar.roundtrip,
      driverBatta: (this.tripType === 'One Way') ? this.selectedCar.onewayDriverFee : this.selectedCar.roundTripDriverFee,
      baseFare: (this.tripType === 'One Way') ? this.routeDetails.totalDistance * this.selectedCar.oneway : (this.routeDetails.totalDistance * 2) * this.selectedCar.oneway,
      tripType: this.tripType,
      carType: this.selectedCar.carType,
      distance: (this.tripType === 'One Way') ? this.routeDetails.totalDistance : this.routeDetails.totalDistance * 2
    }

    console.log('estimation', this.estimation)
  }

  booknow() {

    this.submitted = true;
    if (this.userFromGroup.invalid) {
      return;
    }

    if (!this.selectedCar) {
      alert('Please select vehicle');
      return
    }

    if (!this.origin || !this.destination) {
      alert('Please enter origin & destination details');
      return
    }

    this.getEstimation();

    this.bookingDetails = {
      ...this.userFromGroup.value,
      ...this.routeDetails,
      ...this.estimation,
      bookingId: Math.random().toString(36).substr(2, 9).toLocaleUpperCase()
    }

    let bookingDetails = {
      'bookingId': this.bookingDetails.bookingId,
      'name': this.bookingDetails.name,
      'phoneNo': this.bookingDetails.phone,
      'origin': this.bookingDetails.origin,
      'originCityName': this.bookingDetails.originCityName,
      'destination': this.bookingDetails.destination,
      'destinationCityName': this.bookingDetails.destinationCityName,
      'distance': this.bookingDetails.distance,
      'carType': this.bookingDetails.carType,
      'bookedDate': moment(this.bookingDetails.pickupDate).format('MM/DD/YYYY'),
      'bookedTime': this.bookingDetails.pickupTime,
      'estimate': this.bookingDetails.tripEstimation,
      'duration': this.bookingDetails.totalDuration,
      'tripType': this.bookingDetails.tripType,
      'driverBatta': this.bookingDetails.driverBatta,
      'ratePerKM': this.bookingDetails.ratePerKM,
      'returnDate': (this.bookingDetails.tripType === 'Round Trip') ? moment(this.bookingDetails.returnDate).format('MM/DD/YYYY') : 'NA',
      'adminEmail': this.settings.enquiryEmail,
      'adminPhone': this.settings.phoneNo,
      'adminWhatsapp': this.settings.whatsapp,
      'websiteAddress': this.settings.websiteAddress,
      'siteName': this.settings.siteName,
      'siteInfo': this.settings.siteInfo,
      'adminEmailSubject': `${this.settings.adminEmailSubject} ${this.bookingDetails.name}`,
      'bannerColor': this.settings.bannerColor,
      'appPassword': this.settings.appPassword,
      'appEmail': this.settings.appEmail
    }

  this.http.post(this.settings.emailAPI, bookingDetails).subscribe(res => console.log(res));

    this.stepper.next();
    this.show = true;
  }

  confirm() {

    let bookingDetails = {
      'bookingId': this.bookingDetails.bookingId,
      'name': this.bookingDetails.name,
      'phoneNo': this.bookingDetails.phone,
      'origin': this.bookingDetails.origin,
      'originCityName': this.bookingDetails.originCityName,
      'destination': this.bookingDetails.destination,
      'destinationCityName': this.bookingDetails.destinationCityName,
      'distance': this.bookingDetails.distance,
      'carType': this.bookingDetails.carType,
      'bookedDate': moment(this.bookingDetails.pickupDate).format('MM/DD/YYYY'),
      'bookedTime': this.bookingDetails.pickupTime,
      'estimate': this.bookingDetails.tripEstimation,
      'duration': this.bookingDetails.totalDuration,
      'tripType': this.bookingDetails.tripType,
      'driverBatta': this.bookingDetails.driverBatta,
      'ratePerKM': this.bookingDetails.ratePerKM,
      'returnDate': (this.bookingDetails.tripType === 'Round Trip') ? moment(this.bookingDetails.returnDate).format('MM/DD/YYYY') : 'NA',
      'adminEmail': this.settings.enquiryEmail,
      'adminPhone': this.settings.phoneNo,
      'adminWhatsapp': this.settings.whatsapp,
      'websiteAddress': this.settings.websiteAddress,
      'siteName': this.settings.siteName,
      'siteInfo': this.settings.siteInfo,
      'adminEmailSubject': `${this.settings.adminEmailSubject} ${this.bookingDetails.name}`,
      'bannerColor': this.settings.bannerColor,
      'appPassword': this.settings.appPassword,
      'appEmail': this.settings.appEmail
    }

    let returnDate = `${(bookingDetails.tripType === 'One Way') ? '' : 'Return Date:' + bookingDetails.returnDate}`

    let smsMessage = `
    Thanks for Choosting ${this.settings.siteName}.
    Your Booking Details:\r\n
    Booking ID: ${bookingDetails.bookingId}\r\n
    Name: ${bookingDetails.name}\r\n
    Mobile Number: ${bookingDetails.phoneNo}\r\n
    Pickup Location: ${bookingDetails.origin}\r\n
    Drop Location: ${bookingDetails.destination} \r\n
    Pickup Date/Time: ${bookingDetails.bookedDate} ${bookingDetails.bookedTime} \r\n
    ${returnDate} \r\n
    Trip Type: ${bookingDetails.tripType} \r\n
    Total KM: ${bookingDetails.distance} KM\r\n
    Total Trip Fare: ${bookingDetails.estimate} ₹\r\n
    Rate Per KM: ${bookingDetails.ratePerKM} ₹\r\n
    Driver Batta: ${bookingDetails.driverBatta} ₹\r\n
    Toll, parking, permit extra \r\n 
    For any questions please contact ${this.settings.phoneNo} \r\n 
    https://${this.settings.websiteAddress}`


    let whatsappMessage = window.encodeURIComponent(smsMessage);

    let newSMS = `
    Hi ${bookingDetails.name}, Thanks for booking a Taxi with ${bookingDetails.siteName}. Your booking was confirmed our representative will call in 10-15 mins. For any queries please contact ${this.settings.phoneNo}.
Happy Journey.
    `

    let smsBody = {
      message: newSMS,
      numbers: `${bookingDetails.phoneNo}`,
      smsKey: this.settings.smsKey
    }

   this.http.post(this.settings.smsAPI, smsBody).subscribe(res => console.log(res));

    let whatsupText = `https://api.whatsapp.com/send?phone=${this.settings.whatsapp}&text=${whatsappMessage}`;
    window.open(whatsupText, "_blank");
    this.stepper.next();

  }



}
